import { z, ZodError } from 'zod';
import * as CryptoJS from 'crypto-js';
import { LRUCache } from 'typescript-lru-cache';

const cache = new LRUCache<string, string>({
    maxSize: 100,
    entryExpirationTimeInMS: 5 * 60 * 1000
});

export const fetchJsonData = async (blobPath: string, consumeError: boolean = false): Promise<string> => {
    try {
        if (blobPath === 'undefined') {
            debugger;
        }
        if (blobPath.includes('http')) {
            //console.log("fetchJsonData: blobPath includes http: " + blobPath);
            const url = new URL(blobPath);
            blobPath = url.pathname;
            //console.log("fetchJsonData: blobPath: " + blobPath);
        }

        if (!blobPath.startsWith('/')) {
            blobPath = '/' + blobPath;
        }
        
        if (blobPath.startsWith('/api/content')) {
            blobPath = blobPath.replace('/api/content', '');
        }

        const config = useRuntimeConfig();
        const baseUrl = `${config.public.imgUri}/${config.public.contentName}`;

        // Generate a cache-busting query string parameter (timestamp)
        const cacheBustingParam = `nocache=${Date.now()}`;

        const requestUrl = `${baseUrl}${blobPath}.json`.toLowerCase();

        // Add the cache-busting query string parameter
        const cacheBustedUrl = `${requestUrl}?${cacheBustingParam}`;

        let cacheKey = "";
        // Check if we are on the client-side
        if (process.client) {
            // Generate a safe cache key without the cache-busting parameter
            cacheKey = CryptoJS.SHA256(requestUrl).toString();

            // Check if the data is cached
            if (cache.has(cacheKey)) {
                const cachedValue = cache.get(cacheKey);
            
                // Might return null for cache misses
                if (cachedValue !== null) {
                    return cachedValue;
                }
            }
        }

        let response;
        let blobJson;
        if  (consumeError || process.server) {
        // if  (consumeError) {
            //BF: Catching the error as from umbraco the tab.json on a program is not mandatory to be created. Or else this will bubble and error and redirect to the 404.
            try {
                response = await fetch(cacheBustedUrl);
                blobJson = await response.json();
            }
            catch(err) {
                blobJson = undefined;
            }
        }
        else {
            response = await fetch(cacheBustedUrl);
            if (!response.ok) {
                //return JSON.stringify({});
                throw new Error(`Failed to fetch data from ${cacheBustedUrl}. Status: ${response.status}`);
            }

            blobJson = await response.json();
        }
    

        // Check if we are on the client-side
        if (process.client) {
            // Cache the JSON string with the cache key
            cache.set(cacheKey, blobJson);
        }

        // Return the JSON string
        return blobJson;
    } catch (error) {
        // Handle the error here or return an error object
        console.error(error);
        throw error;
    }
}

export const fetchPreviewJsonData = async (blobPath: string, consumeError: boolean = false): Promise<string> => {
    try {
        if (blobPath.includes('http')) {
      //      //console.log("fetchJsonData: blobPath includes http: " + blobPath);
            const url = new URL(blobPath);
            blobPath = url.pathname;
      //      //console.log("fetchJsonData: blobPath: " + blobPath);
        }

        if (!blobPath.startsWith('/')) {
            blobPath = '/' + blobPath;
        }
        
        if (blobPath.startsWith('/api/content')) {
            blobPath = blobPath.replace('/api/content', '');
        }

        const config = useRuntimeConfig();
        const baseUrl = `${config.public.imgUri}/${config.public.contentName}`;

        // Generate a cache-busting query string parameter (timestamp)
        const cacheBustingParam = `nocache=${Date.now()}`;

        const requestUrl = `${baseUrl}${blobPath}.json`.toLowerCase();

        // Add the cache-busting query string parameter
        const cacheBustedUrl = `${requestUrl}?${cacheBustingParam}`;

        let cacheKey = "";
        // Check if we are on the client-side
        if (process.client) {
            // Generate a safe cache key without the cache-busting parameter
            cacheKey = CryptoJS.SHA256(requestUrl).toString();

            // Check if the data is cached
            if (cache.has(cacheKey)) {
                const cachedValue = cache.get(cacheKey);
            
                // Might return null for cache misses
                if (cachedValue !== null) {
                    return cachedValue;
                }
            }
        }

        let response;
        let blobJson;
        
        response = await fetch(cacheBustedUrl);
        if (!response.ok) {
            //return JSON.stringify({});
            return '';
        }

        blobJson = await response.json();
    

        // Check if we are on the client-side
        if (process.client) {
            // Cache the JSON string with the cache key
            cache.set(cacheKey, blobJson);
        }

        // Return the JSON string
        return blobJson;
    } catch (error) {
        // Handle the error here or return an error object
        console.error(error);
        throw error;
    }
}
// Function to parse JSON using Zod
export function parseJSON<T extends z.Schema<any>>(schema: T, json: string): z.infer<T> | [] {
    try {

        return schema.parse(json);
    } catch (err) {
        if (err instanceof ZodError) {
      //      //console.log("ZodError:" + JSON.stringify(err.issues, null, 2));
        } else {
      //      //console.log("parseJSON ERROR: " + JSON.stringify(err, null, 2))
        }
        
        return [] as z.infer<T>;
    }
}

// Function to parse JSON using Zod
export function parseJSONObject<T extends z.Schema<any>>(schema: T, json: string): z.infer<T> | {} {
    try {
        return schema.parse(json);
    } catch (err) {
        if (err instanceof ZodError) {
      //      //console.log("ZodError:" + JSON.stringify(err.issues, null, 2));
        } else {
      //      //console.log("parseJSON ERROR: " + JSON.stringify(err, null, 2))
        }
        
        return {} as z.infer<T>;
    }
}

export function parseJSONObjectV2<T extends z.Schema<any>>(schema: T, json: string): z.infer<T> | {} {
    try {
        const jsonObject = JSON.parse(json);
        const result = schema.parse(jsonObject);
        return result;
    } catch (err) {
        debugger;
        if (err instanceof ZodError) { 
            console.log("ZodError:" + JSON.stringify(err.issues, null, 2));
            console.log("ZodError json:" + JSON.stringify(JSON.parse(json), null, 2));
        } else { 
            console.log("parseJSON ERROR: " + JSON.stringify(err, null, 2))
            console.log("ZodError json:" + json);
        }
        
        return {} as z.infer<T>;
    }
}

export async function getBlobContent(blobUrl: string, bustCache = false) {
    let blobPath = bustCache ? `${blobUrl}?v=${Date.now()}` : blobUrl;
    if (!blobPath.startsWith('/')) {
        blobPath = '/' + blobPath;
    }

    const config = useRuntimeConfig();
    const baseUrl = `${config.public.imgUri}/${config.public.contentName}`;
    const requestUrl = `${baseUrl}${blobPath}`.toLowerCase();

    const response = await fetch(requestUrl);
    const blobXml = await response.text();

    return blobXml;
}
